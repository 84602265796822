<template>
    <div class="main-map-chart"
      ref="dataMap"
      style="width: 100%; height: 100%"
     >
    </div>
  </template>
  <script>
  import '../../assets/china'
  let chart = null
  export default {
    props: {
      title: String,
      list: Array,
      listLine: Array,
    },
    data () {
      return {
        config: {}
      }
    },
    methods: {
      initChart () {
        if (null != chart && undefined != chart) {
          chart.dispose()
        }
        chart = this.$echarts.init(this.$refs.dataMap)
        chart.on('click', this.handleMapClick)
        this.setOptions()
      },
      setOptions() {
        let option = {
          title: {
            text: "项目全国分布图",
            top: 50,
            left: 'center',
            textStyle: {
              fontWeight: 'bolder',
              fontSize: 24,
              color: '#BCBCBF'
            }
          },
          tooltip: {
            trigger: `item`,
            extraCssText:"border:none;",
            hideDelay: 3000,
            enterable:true,//可以让鼠标进入tooltip
            triggerOn: "mousemove|click", // 出发条件
            backgroundColor: "#0593A3",
            formatter:function(params){  //使用formatter函数修改需要的样式
              let res=""
                  res+='<div>'+
                    '<div style="color:#fff;padding:10% 1%;font-size:12px">'
                      +'<span>'+ params.data.name
                      +'</span></br>'
                      +'<span>终端:' + params.data.terminalNum + '</span></br>'
                      +'<span>' + params.data.address + '</span></br>'
                      +'</div>'
                  return res
                }
               },
          geo: {
            type:"map",
            map: 'china',
            roam: false, // 开启缩放和平移
            zoom: 1.23, // 当前视角的缩放比例
            top: '10%',
            left: '10%',
            label: {
              normal: {
                show: !0,
                fontSize: '14',
                color: 'rgba(255,255,255,0.7)',
              },
            },
            select: {disabled: false, // 是否可以被选中。在开启selectedMode的时候有效，可以用于关闭部分数据。// 选中区域文本标签label: { color: '#ff00ff',},// 高亮状态下图形样式itemStyle: {areaColor: '#67c23a', // 高亮区域的颜色。borderColor: 'rgba(0, 0, 0,1)', // 图形的描边颜色。borderWidth: 4, // 描边线宽。为 0 时无描边。borderType: 'dashed', // 描边类型。},
              },
            itemStyle: {
              //每一块区域的样式
              areaColor: "#0593A3",
              borderColor: "#9FCFFD",
              borderWidth: 0.5,
            },
            emphasis: {
              disabled: false, //取消鼠标经过高亮
              itemStyle: {
                areaColor: "#31C6BC",
              },
            },
          },
          series: [
            //绘制飞线
            {
              type: "lines",
              coordinateSystem: "geo",
              zlevel: 5,
              effect: {
                show: true,
                period: 2, //箭头指向速度，值越小速度越快
                trailLength: 0.2, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: "triangle", //箭头图标
                symbolSize: 5, //图标大小
                color: "#37F6E0", // 图标颜色
              },
              lineStyle: {
                normal: {
                  show: true,
                  width: 1, //尾迹线条宽度
                  opacity: 1, //尾迹线条透明度
                  curveness: 0.3, //尾迹线条曲直度
                  color: "#37F6E0", // 飞线颜色
                },
                color: "#37F6E0",
              },
              // data: [
              //   {
              //     coords: [
              //       [102.712251, 25.040609], // 起点  云南
              //       [103.823557, 36.058039], // 终点  甘肃
                    
              //     ],
              //   },
              // ],
              data:this.listLine
            },
            //绘制飞线结束
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              effectType: "ripple", //涟漪特效
              showEffectOn: "render",
              // 散点样式
              rippleEffect: {
                color: "#37F6E0",
                period: 1, // 涟漪特效的动画周期
                scale: 2, // 涟漪特效动画中波纹的最大缩放比例
                brushType: "fill", // 涟漪特效的波纹绘制方式
              },
              // 散点大小
              symbolSize: 10,
              hoverAnimation: true,
              // 标志的样式
              itemStyle: {
                color: "rgba(55,246,224, .7)",
              },
              zlevel: 3,
             data:this.list
            },
          ],
        }
        chart.setOption(option)
      },
      handleMapClick(params) {
        if(params.componentSubType == "effectScatter")
        {
          this.$emit('map-click', params.data.id)
        }
      },
    },
    mounted() {
        chart = this.$echarts.init(this.$refs.dataMap)
        chart.on('click', this.handleMapClick)
        this.setOptions()
    },
    watch: { 
      list: {
        handler (newValue, oldValue) {
          if (oldValue != newValue) {
            this.setOptions()
          }
        },
        deep: true
      }
    }
  }
  </script>
  <style>
</style>