<template>
  <div class="basic-data-item">
    <div class="basic-data-item-title">{{ label }}
      <span :class="[ litterNumber > 0 ? valueUpClass: valueDownClass, valueClass]">
        {{ litterNumber }}
      </span>
    </div>
    <div class="basic-data-item-value">
      <dv-digital-flop
        :config="config"
        style="width: 140px; height: 50px"
      />
    </div>
    <dv-decoration-2 style="width:100px;height:5px;" :dur="10"/>
  </div>
  
</template>
<script>
export default {
  props: {
    label: String,
    config: Object,
    litterNumber: Number,
  },
  data() {
    return {
      valueUpClass: 'incremento-up-value',
      valueDownClass: 'incremento-down-value',
      valueClass: 'basic-data-item-incremento-value'
    }
  }
}
</script>
<style scoped>
.basic-data-item {
  background: #0f142b;
  margin: 0 5px 5px 5px;
  padding: 15px 10px 10px 10px;
  border-radius: 5px;
}
.incremento-up-value {
  color: #60FE5E;
}
.incremento-down-value {
  color: #F44182;
}
.basic-data-item-title {
  color: #bcbcbe;
  font-size: 16px;
  padding-bottom: 5px;
}
.basic-data-item-value {
  color: #fff;
  font-size: 40px;
  font-weight: bolder;
}
</style>