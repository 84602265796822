<template>
    <div class="bottom-right-table-3">
      
      
        <dv-border-box-7 style="width:100%;height:200px">
        <dv-scroll-board :config="configs" @click="itemClicked" />
      </dv-border-box-7>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      configs: Object
    },
    data () {
      return {
        
      config: {
          header:['ID','项目名称','地区'],
          data: [
            ['1','栏杆机不抬杆', '月累计4起'],
            ['2','栏杆机落杆', '月累计3起'],
            ['3','光端机故障', '月累计3起'],
            ['4','票据打印机', '月累计3起'],
            ['5','票据打印机', '月累计3起'],
            ['6','票据打印机', '月累计3起'],
            ['7','票据打印机', '月累计3起'],
            ['8','票据打印机', '月累计3起'],
          ],
          index: false,
          columnWidth: [60],
          align: ['center'],
          headerBGC: '#0f1325', //表头
          oddRowBGC: 'rgba(9, 37, 50, 0.4)',
          evenRowBGC: 'rgba(10, 32, 50, 0.3)',
          carousel:'page',
          rowNum:4
          
        }
      }
    },
    methods: {
      itemClicked(value){
        this.$emit('project-click', value.row[0])
      }
    }
  }
  
  </script>
  
  <style >
  
  .table-name {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    color:"#fff"
  }
  </style>
  