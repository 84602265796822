<template>
    <div style="margin:10px">
      <dv-decoration-11 style="width:100%;height:60px;color:#ffffff;">
          月报警率
      </dv-decoration-11>
      <div class ="charts-chart" ref = "alarmChart">
        
      </div>
    </div>
    
</template>
<script>
import * as echarts from 'echarts';
let chart = null
export default {
props: {
  options:{
    type:Object,
    default:()=>{}
  }
},
methods:{
  initChart () {
    
  },
  setOptions(){
     let option = {
        grid: {
          top: 20,
          bottom: 30,
          left:20,
          containLabel:true
          },
      xAxis: {
        type: 'category',
        data: this.options.dateList,
        axisLabel: {
            show:false,
            color: '#fff',
            fontSize: 14,
            interval:0, 
            rotate:45,
          },
      },
      yAxis: {
        type: 'value',
        axisLabel:{
          color: '#fff',
          fontSize: 14
        }
      },
      series: [
        {
          data: this.options.numsList,
          type: 'line',
          smooth: true,
          itemStyle:{
            normal: {
              //这里是颜色
              color: function(params) {
              //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
              var colorList = ['#00A3E0','#FFA100', '#ffc0cb', '#CCCCCC', '#BBFFAA','#749f83', '#ca8622'];
                  return colorList[params.dataIndex]
              }
            }
          }
        }
]
     }
     this.chart.setOption(option)
  }
},
data() {
  return {
    chart: null
  };
},
mounted() {
  this.chart = echarts.init(this.$refs.alarmChart)
},
watch: {
  options: {
    handler (newValue, oldValue) {
      if (oldValue != newValue) {
        this.setOptions()
      }
    },
    deep: true
  }
},
beforeDestroy() {
  if (this.chart) {
    this.chart.dispose();
    this.chart = null;
  }
}
};
</script>

<style scoped>
.charts-chart {
margin-top:0;
height: 200px;
width:100%
}
</style>
