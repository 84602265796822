<template>
    <div class="left-chart-2">
      <div class="lc2-header">终端在线率</div>
      <div class="lc2-details">终端总数<span class="spanSty">{{ TerminalNum }}</span></div>
      <dv-decoration-9 style="width:150px;height:150px;margin:auto;">{{Percents}}%</dv-decoration-9>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        TerminalNum:{
            type:Number,
            default:0
        },
        Percents:{
            type:Number,
            default:0
        }
    },
    data () {
      return {
        
      }
    },
    
  }
  </script>
  
  <style >
  .left-chart-2 {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    margin-top:0;
  }
    .lc2-header {
      height: 20px;
      line-height: 20px;
      font-size: 16px;
      text-indent: 20px;
      margin-top: 10px;
    }
  .spanSty {
      color: #096dd9;
      font-weight: bold;
      font-size: 35px;
      margin-left: 20px;
    }
  </style>
  