<template>
    <div class="main-content">
        <dv-border-box-9>
            <div class="main-inner">
                <el-row>
                    <el-col :span="6">
                        <AlarmCmp 
                            :options="alarmOptions"
                        />
                        <AlarmMonth 
                            :options="alarmMonthOptions" 
                            style="padding:5px"
                        />
                        <TerminalStatus 
                            style="width:100%;height:300px;margin: 0 auto;"
                            :TerminalNum="TerminalNum"
                            :Percents = "TerminalPercent"
                        />   
                    </el-col>
                    <el-col :span="12">
                        <div class="planes">
                            <el-row>
                                <el-col :span="6">
                                    <DigitalPlane 
                                    label="现有项目"
                                    :config = "TopDigitalPlaneData.projectConfig"
                                    />
                                </el-col>
                                <el-col :span="6">
                                    <DigitalPlane 
                                    label="终端"
                                    :config = "TopDigitalPlaneData.terminalConfig"
                                    :litterNumber = "DigitalData.Terminal"
                                    />
                                </el-col>
                                <el-col :span="6">
                                    <DigitalPlane 
                                    label="在线电机"
                                    :config = "TopDigitalPlaneData.motorConfig"
                                />
                                </el-col>
                                <el-col :span="6">
                                    <DigitalPlane 
                                    label="报警量"
                                    :config = "TopDigitalPlaneData.alarmConfig"
                                    :litterNumber = "DigitalData.Alarm"
                                    />
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>
                            <div class="main-inner-map-wraper">
                                    <!-- 地图 -->
                                <div class="map">
                                    <ProjectMap
                                        :list="mapDataList.points"
                                        :listLine = "mapDataList.lines"
                                        @map-click = "handleMapClick"
                                    />
                                </div>
                            </div>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <!-- <ProjectTable 
                            title="项目列表"
                            style="margin:10px"
                            :configs = "ProjectList"
                            @project-click = "projectcClick"
                        /> -->
                        <TerminalTable 
                            title="终端"
                            style="margin:10px"
                            :configs = "TerminalList"
                            @terminal-click = "terminalClick"
                        />
                        <AlarmTable 
                            title="报警列表"
                            :configs = "AlarmWeekList"
                            style="margin:10px"
                        />
                    </el-col >
                    
                </el-row>
            </div>
        </dv-border-box-9>
    </div>
</template>
<script>
import AlarmCmp from "../components/ds/AlarmCmp"
import AlarmMonth from "../components/ds/AlarmMonth"
import TerminalStatus from "../components/ds/TerminalStatus"
import DigitalPlane from "../components/ds/DigitalPlane"
import ProjectMap from "../components/ds/ProjectMap"
import AlarmTable from "../components/ds/AlarmTable"
import TerminalTable from "../components/ds/TerminalTable"
import ProjectTable from "../components/ds/ProjectTable"

import dsRequest from '../api/ds'

const formatter = (number) => {
  const numbers = number.toString().split('').reverse()
  const segs = []
  while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
  return segs.join(',').split('').reverse().join('')
}
// 数据样式
const getNumberStyle = (color = '#E8EAF6', fontSize = 30, fontWeight = 'bolder') => {
  return {
    fontSize: fontSize,
    fill: color,
    fontWeight: fontWeight
  }
}
const initDigitalPlaneData = (data = null) =>{
    
    let projectNumber = data ? [data.Projects] : 0
    let terminalNumber = data ? [data.onLineTerminal] : 0
    let motorNumber = data ? [data.Motors] : 0
    let alarmNumber = data ? [data.AlarmCur] : 0
    return {
        projectConfig:{
            number: [projectNumber],
            content: '{nt}',
            formatter,
            style: getNumberStyle()
        },
        terminalConfig:{
            number: [terminalNumber],
            content: '{nt}',
            formatter,
            style: getNumberStyle()
        },
        motorConfig:{
            number: [motorNumber],
            content: '{nt}',
            formatter,
            style: getNumberStyle()
        },
        alarmConfig:{
            number: [alarmNumber],
            content: '{nt}',
            formatter,
            style: getNumberStyle()
        }
    }
}
export default ({
    beforeDestroy() { //订阅事件记得要取消
        console.log("clear")
    },
    components:{
        AlarmCmp,
        AlarmMonth,
        TerminalStatus,
        DigitalPlane,
        ProjectMap,
        // ProjectTable,
        TerminalTable,
        AlarmTable
    },
    data(){
        return{
            alarmOptions: {
                dateList:[],
                numsList:[]
            },
            alarmMonthOptions:{
                dateList:[],
                numsList:[] 
            },
            TerminalNum:0,
            TerminalPercent:0,//终端在线率,
            DigitalData:[],
            mapDataList:{},
            AlarmWeekList:{},
            ProjectList:{},
            TerminalList:{},
            TopDigitalPlaneData:initDigitalPlaneData(),


        }
    },
    methods: {
        handleMapClick(project_id)
        {
            this.curProjectId = project_id
            this.getTerminalList()
        },
        terminalClick(terminal_id)
        {
            this.$emit('terminal-data', terminal_id)
        },
        terminalAndMotors()
        {
            this.getTerminalList()
        },
        getProjectList(){
            //获取项目列表
            dsRequest.getProjectList({province_name:this.curProvinceName}).then((res) =>{
                if (res.code == 1) {
                    this.ProjectList = {
                        header:['项目ID','项目名称','地址'],
                        data: res.data,
                        index: false,
                        columnWidth: [100],
                        align: ['center'],
                        headerBGC: '#0f1325', //表头
                        oddRowBGC: 'rgba(9, 37, 50, 0.4)',
                        evenRowBGC: 'rgba(10, 32, 50, 0.3)',
                        carousel:'single',
                        rowNum:4
                    }    
                } 
            })
        },
        getTerminalList()
        {
                //获取终端列表
                dsRequest.getTerminalListByProjectId({project_id:this.curProjectId,province_name:this.curProvinceName}).then((res) =>{
                if (res.code == 1) {
                    this.TerminalList = {
                        header:['名称','状态','终端ID'],
                        data: res.data,
                        index: false,
                        columnWidth: [100],
                        align: ['center'],
                        headerBGC: '#0f1325', //表头
                        oddRowBGC: 'rgba(9, 37, 50, 0.4)',
                        evenRowBGC: 'rgba(10, 32, 50, 0.3)',
                        carousel:'single',
                        rowNum:10
                    }
                    let terminalArr = [];
                    res.data.forEach(function(e) {
                        terminalArr.push(e[2])
                        })
                } 
                this.curProjectId = 0
            })
        },
        startQueryData(){
            dsRequest.getTopBaseInfo().then((res) =>{
            if (res.code == 1) {
                this.TerminalNum = res.data.Terminal
                this.TerminalPercent = res.data.TerminalPecent
                this.setBasickData(res.data)
                } 
            })
        },
        setBasickData(data){
            let config = initDigitalPlaneData(data)
            this.DigitalData = data
            this.TopDigitalPlaneData = config
        },
    },
    mounted(){
        let self = this
        dsRequest.getAlarmAllCount().then((res) =>{
            if (res.code == 1) {
                this.alarmOptions = {
                    dateList : res.data.dateList,
                    numsList : res.data.numList
                }    
            } 
        }),
        dsRequest.getAlarmAllMonthCount().then((res) =>{
            if (res.code == 1) {
                this.alarmMonthOptions = {
                    dateList : res.data.dateList,
                    numsList : res.data.numList
                }    
            } 
        }),
        dsRequest.getTopBaseInfo().then((res) =>{
            if (res.code == 1) {
                this.TerminalNum = res.data.Terminal
                this.TerminalPercent = res.data.TerminalPecent
              //  this.setBasickData(res.data)
                } 
            })
        dsRequest.getAllProjects().then((res) =>{
            if (res.code == 1) {
                this.mapDataList = res.data
              //  this.setBasickData(res.data)
                } 
        }),
        //终端一周内的报警情况
        dsRequest.getAlarmWeekData().then((res) =>{
            if (res.code == 1) {
                this.AlarmWeekList = {
                    header:['终端名称','类型','异常值'],
                    data: res.data,
                    index: false,
                    columnWidth: [200],
                    align: ['center'],
                    headerBGC: '#0f1325', //表头
                    oddRowBGC: 'rgba(9, 37, 50, 0.4)',
                    evenRowBGC: 'rgba(10, 32, 50, 0.3)',
                    carousel:'single',
                    rowNum:10
                }    
            } 
        })
        this.terminalAndMotors()   
        // 定义定时器，隔 5 秒刷新一次
        let timer = setInterval(() => {
            self.startQueryData()
        }, 5000)
        this.$once('hook:beforeDestroy', () => {
            clearInterval(timer);
            timer = null;
        })

    }
})
</script>
<style scoped>
.planes{
    margin-top:20px;
    margin-left:20px;
}
.map {
  width: 99%;
  height:700px;
  padding: 20px 10px 10px 10px;
}
</style>