<template>
    <div class="bottom-right-table-3">
      <dv-decoration-7 style="width:150px;height:30px;margin:0 auto;">报警列表</dv-decoration-7>
        <dv-border-box-7 style="width:100%;height:350px">
        <dv-scroll-board :config="configs" @click="itemClicked" />
      </dv-border-box-7>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      configs: Object
    },
    data () {
      return {
        
      config: {
          header:['报警终端','类型','异常值'],
          data: [
            ['栏杆机不抬杆','栏杆机不抬杆', '月累计4起'],
            ['栏杆机不抬杆','栏杆机落杆', '月累计3起'],
            ['栏杆机不抬杆','光端机故障', '月累计3起'],
            ['栏杆机不抬杆','票据打印机', '月累计3起'],
            ['栏杆机不抬杆','票据打印机', '月累计3起'],
            ['栏杆机不抬杆','票据打印机', '月累计3起'],
            ['栏杆机不抬杆','票据打印机', '月累计3起'],
            ['栏杆机不抬杆','票据打印机', '月累计3起'],
          ],
          index: false,
          columnWidth: [200],
          align: ['center'],
          headerBGC: '#0f1325', //表头
          oddRowBGC: 'rgba(9, 37, 50, 0.4)',
          evenRowBGC: 'rgba(10, 32, 50, 0.3)',
          carousel:'page',
          rowNum:4
          
        }
      }
    },
    methods: {
      itemClicked(value){
        console.log(value)
      }
    }
  }
  
  </script>
  
  <style >
  
  .table-name {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    color:"#fff"
  }
  </style>
  